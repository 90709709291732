// OPEN SANS
@font-face {
  font-family: 'Montserrat', sans-serif;
  font-display: swap;
  unicode-range: U+000-5FF;
  src:
      local('Montserrat-SemiBold');
      // url('https://fonts.googleapis.com/css?family=Open+Sans');
  font-weight: 600;
  font-style: normal;
  font-variant:normal;
}

@font-face {
  font-family: 'Montserrat', sans-serif;
  font-display: swap;
  unicode-range: U+000-5FF;
  src:
      local('Montserrat-Bold');
      // url('https://fonts.googleapis.com/css?family=Open+Sans');
  font-weight: bold;
  font-style: normal;
  font-variant:normal;
}

@font-face {
  font-family: 'Montserrat', sans-serif;
  font-display: swap;
  unicode-range: U+000-5FF;
  src:
      local('Montserrat-Regular');
      // url('https://fonts.googleapis.com/css?family=Open+Sans');
  font-weight: normal;
  font-style: normal;
  font-variant:normal;
}

@font-face {
  font-family: 'Montserrat', sans-serif;
  font-display: swap;
  unicode-range: U+000-5FF;
  src:
      local('Montserrat-ExtraBold');
      // url('https://fonts.googleapis.com/css?family=Open+Sans');
  font-weight: 800;
  font-style: normal;
  font-variant:normal;
}

@font-face {
  font-family: 'Montserrat', sans-serif;
  font-display: swap;
  unicode-range: U+000-5FF;
  src:
      local('Montserrat-Light');
      // url('https://fonts.googleapis.com/css?family=Open+Sans');
  font-weight: 300;
  font-style: normal;
  font-variant:normal;
}
