// Bootstrap overrides
// 
// Color system
$white:                     #fff;
$gray-100:                  #f8f9fa;
$gray-200:                  #e9ecef;
$gray-300:                  #dee2e6;
$gray-400:                  #ced4da;
$gray-500:                  #adb5bd;
$gray-600:                  #6c757d;
$gray-700:                  #495057;
$gray-800:                  #343a40;
$gray-900:                  #212529;
$black:                     #000000;

// Custom color system
$white-solitude:            #F5F6F9;
$slate-grey:                #6E7F95;
$cornflower-blue:           #6480EB;
$deep-blue:                 #00479D;
$hawkes-blue:               #CADBEA;
$orange-peel:               #F39800;
$saffron:                   #F4C430;
$tenne:                     #D06002;
$summer-sky:                #1EBAEE;
$summer-sky-light:          rgb(122, 212, 241);
$bittersweet:               #FF6560;
$bittersweet-light:         rgb(255, 138, 134);
$caribbean-green:           #00D7AF;
$madison:                   #2C3652;
$light-grey:                #B6BDCB;
$lighter-grey:              #F1F1F1;
$cobalt:                    #00479D;
$olive:                     #6E4500;

// simple color name
$blue:                      #007bff;
$indigo:                    #6610f2;
$purple:                    #6f42c1;
$pink:                      #e83e8c;
$red:                       #dc3545;
$orange:                    #fd7e14;
$yellow:                    $saffron;
$green:                     #28a745;
$teal:                      #20c997;
$cyan:                      #17a2b8;


$colors: (
  "orange-peel": $orange-peel
);

$theme-colors: (
  "orange-peel": $orange-peel
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

$primary:                     $orange-peel;
$secondary:                   $gray-600;
$danger:                      $bittersweet;
$info:                        $summer-sky;
$link-color:                  $summer-sky;

// Components
//
// Define common padding and border radius sizes and more.

// $line-height-lg:              1.5 !default;
// $line-height-sm:              1.5 !default;

$border-width:                2px;
// $border-color:                $gray-300 !default;

// $border-radius:               .25rem !default;
// $border-radius-lg:            .3rem !default;
// $border-radius-sm:            .2rem !default;

// $rounded-pill:                50rem !default;

// $box-shadow-sm:               0 .125rem .25rem rgba($black, .075) !default;
// $box-shadow:                  0 .5rem 1rem rgba($black, .15) !default;
// $box-shadow-lg:               0 1rem 3rem rgba($black, .175) !default;

// $component-active-color:      $white !default;
// $component-active-bg:         theme-color("primary") !default;

// $caret-width:                 .3em !default;
// $caret-vertical-align:        $caret-width * .85 !default;
// $caret-spacing:               $caret-width * .85 !default;

// Transition
$transition-base:             all .2s ease-in-out;
$transition-fade:             opacity .15s linear;
$transition-collapse:         height .35s ease;

// Grid columns
$grid-columns:                12;
$grid-gutter-width:           30px;
$grid-container-padding-lg:   40px;
$grid-container-padding-sm:   ($grid-gutter-width / 2);

// Border radius
$border-radius:               0.9375rem;
$border-radius-lg:            1.1rem;
$border-radius-md:            0.9375rem;
$border-radius-sm:            0.75rem;
// $border-radius-xs:            4.25rem;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret:                                true;
$enable-rounded:                              true;
$enable-shadows:                              false;
$enable-gradients:                            false;
$enable-transitions:                          true;
$enable-prefers-reduced-motion-media-query:   true;
$enable-hover-media-query:                    false; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes:                         true;
$enable-pointer-cursor-for-buttons:           true;
$enable-print-styles:                         true;
$enable-responsive-font-sizes:                true;
$enable-validation-icons:                     true;
$enable-deprecation-messages:                 true;


// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.
$input-btn-padding-y:         .6875rem;
$input-btn-padding-x:         1.38rem;
// $input-btn-font-family:       null !default;
// $input-btn-font-size:         $font-size-base !default;
// $input-btn-line-height:       $line-height-base !default;

$input-btn-focus-width:       0;
$input-btn-focus-color:       transparent;
$input-btn-focus-box-shadow:  none;

$input-btn-padding-y-sm:      .375rem;
$input-btn-padding-x-sm:      .65rem;
// $input-btn-font-size-sm:      $font-size-sm !default;
// $input-btn-line-height-sm:    $line-height-sm !default;

$input-btn-padding-y-lg:      .875rem;
$input-btn-padding-x-lg:      1.4rem;
// $input-btn-font-size-lg:      $font-size-lg !default;
// $input-btn-line-height-lg:    $line-height-lg !default;

// $input-btn-border-width:      $border-width !default;


$btn-font-weight:             500;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           $border-radius;
$btn-border-radius-lg:        $border-radius-lg;
$btn-border-radius-sm:        $border-radius-sm;

// Fonts
$font-family-montserrat:      'Montserrat', sans-serif, monospace;
$font-family-opensans:        'Open Sans', sans-serif, monospace;
$font-family-notosans-jp:     'Noto Sans JP', sans-serif, monospace;
$font-family-base:            $font-family-notosans-jp;

// Heading
// $headings-margin-bottom:      $spacer / 2 !default;
// $headings-font-family:        null !default;
$headings-font-weight:        700;
// $headings-line-height:        1.2 !default;
$headings-color:              $madison;

// Toasts

$toast-max-width:                   320px;
$toast-padding-x:                   1rem !default;
$toast-padding-y:                   .6rem !default;
// $toast-font-size:                   .875rem !default;
$toast-color:                       $madison;
// $toast-background-color:            rgba($white, .85) !default;
$toast-border-width:                2px;
// $toast-border-color:                $orange-peel;
$toast-border-radius:               $border-radius;
// $toast-box-shadow:                  0 .25rem .75rem rgba($black, .1) !default;

$toast-header-color:                $madison;
// $toast-header-background-color:     rgba($white, .85) !default;
// $toast-header-border-color:         rgba(0, 0, 0, .05) !default;

// Z-index master list
$zindex-dropdown:                               1000;
$zindex-sticky:                                 1020;
$zindex-fixed:                                  1030;
$zindex-notification:                           1039;
$zindex-modal-backdrop:                         1040;
$zindex-modal:                                  1050;
$zindex-popover:                                1060;
$zindex-tooltip:                                1070;
$zindex-loading:                                1080;

// Top Navigation
$navigation-height-md:                          72px;
$navigation-height-sm:                          72px;

// Sidebar
$max-screen-collapse:                           1024px;
$sidebar-width-lg:                              281px;
$sidebar-width-sm:                              126px; // 80 is good

// Policy status color
$policy-status-pending:                         $saffron;
$policy-status-approved:                        $caribbean-green;
$policy-status-rejected:                        $bittersweet;

// language - font
$language-fonts: (
  en: $font-family-montserrat,
  ja: $font-family-notosans-jp,
);

$orange-filter: invert(59%) sepia(60%) saturate(3528%) hue-rotate(11deg) brightness(107%) contrast(103%);
