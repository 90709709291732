.main-margin {
  max-width: 1200px;
  margin: auto;
  padding: 0 30px;

  @media screen and (max-width: 991px) {
    padding: 0 15px;
  }
}

body {
  max-width: 100vw;
  overflow-x: hidden;
}

p {
  margin: 0;
  color: #8C8B99;
  font-size: 1.1875rem;
  font-weight: normal;
}

hr {
  background-color: #88DBA8;
  width: 46px;
  height: 7px;
  border-radius: 3.5px;
  border: 0;
  margin-left: 0;
}

%button-custom {
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.05);
  }
  &:active {
    transition: transform 0.05s;
    transform: scale(1);
  }
}

.flex-1 {
  flex: 1;
}

.btn-info {
  background-color: #387EDC;
  border: 4px #FFFFFF88 solid;
}

.fa{
  font-family: FontAwesome !important;
 }
 